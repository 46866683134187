/* NotificationItem.css */

.notification-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    transition: background-color 0.2s;
    background-color: #2a2a2a;
    border-bottom: 1px solid #333;
    border-radius: 0;
}

.notification-item:hover {
    background-color: #3a3a3a;
}

.notification-item > div {
    display: flex;
    align-items: center;
    gap: 12px;
    flex: 1;
}

.notification-item > div > img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    flex-shrink: 0;
    object-fit: cover;
}

.notification-content {
    flex: 1;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
}

.notification-content span {
    text-align: left;
    display: inline;
    color: #ffffff;
}

.notification-content .timestamp {
    color: #888;
    font-size: 12px;
    margin-left: 4px;
}

.notification-item p {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
    display: inline;
}

.notification-item h3 {
    color: #ffffff;
    font-size: 14px;
    margin: 0;
    line-height: 1.4;
    display: inline;
}

.notification-item a {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
}

.notification-item a:hover {
    text-decoration: underline;
}

.notification-item .feed-item-body {
    margin-top: 4px;
}

.notification-item .feed-item-right {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-left: 8px;
}

.notification-item button {
    background-color: #3a3a3a;
    color: #ffffff;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    transition: background-color 0.2s;
}

.notification-item button:hover {
    background-color: #4a4a4a;
}

.notification-item .follow-button {
    padding: 4px 12px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    /* Not following state */
    background-color: #fff;
    color: #000;
    border: none;
}

.notification-item .follow-button:hover {
    background-color: #f0f0f0;
}

/* Following state */
.notification-item .follow-button.following {
    background-color: #111;
    color: #fff;
    border: 1px solid #333;
}

.notification-item .follow-button.following:hover {
    background-color: #222;
}

/* Event notification specific styles */
.notification-item .feed-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.notification-item .feed-item-left {
    flex: 1;
}

.notification-item .feed-item-header {
    margin-bottom: 4px;
}

.notification-item .countdown {
    color: #007bff;
    font-weight: 500;
    margin-top: 2px;
    font-size: 12px;
}

/* Error state */
.notification-item.error {
    background-color: rgba(255, 0, 0, 0.1);
    border-left: 2px solid rgba(255, 0, 0, 0.2);
}

/* Unread state */
.notification-item.unread {
    background-color: rgba(0, 123, 255, 0.1);
    border-left: 2px solid #007bff;
}

/* Action buttons */
.action-button {
    background-color: #0095f6;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    margin-left: auto;
}

.action-button:hover {
    background-color: #1877f2;
}

/* Specific notification type styles */
.share-buttons {
    display: flex;
    gap: 6px;
}

.attendees-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
}

.attendee-item {
    display: flex;
    align-items: center;
    gap: 6px;
    max-width: 100%;
}

.attendee-pic {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.attendee-name {
    font-size: 13px;
    text-align: left;
}

.view-event-button,
.text-friends-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    text-align: left;
}

.view-event-button:hover,
.text-friends-button:hover {
    opacity: 0.8;
}

.profile-pic {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 6px;
    vertical-align: middle;
}

.loading,
.no-more,
.no-activities {
    text-align: center;
    padding: 8px;
    color: #888;
    font-size: 13px;
    background-color: transparent;
}

/* Gym notification specific styles */
.notification-item .gym-notification {
    display: flex;
    align-items: center;
    gap: 4px;
}

.notification-item .gym-notification p,
.notification-item .gym-notification .timestamp {
    margin: 0;
    display: inline;
}

@media screen and (max-width: 390px) {
    /* Base notification text */
    .notification-item .notification-content span,
    .notification-item .notification-content p,
    .notification-item h3,
    .notification-item a,
    .notification-item .feed-item-body,
    .notification-item .attendee-name,
    .notification-item .gym-notification p {
        font-size: 12px;
        line-height: 1.2;
    }

    /* Timestamps and secondary text */
    .notification-item .notification-content .timestamp,
    .notification-item .countdown {
        font-size: 10px;
    }

    /* Buttons */
    .notification-item button,
    .notification-item .follow-button,
    .notification-item .action-button,
    .notification-item .view-event-button,
    .notification-item .text-friends-button {
        font-size: 12px;
        padding: 3px 10px;
    }

    /* Profile pictures */
    .notification-item > div > img,
    .notification-item .profile-pic,
    .notification-item .attendee-pic {
        width: 24px;
        height: 24px;
    }

    /* Status messages */
    .loading,
    .no-more,
    .no-activities {
        font-size: 11px;
    }
}
