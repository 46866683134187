.about-container {
    padding: 40px;
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
    color: #343a40;
}

@media (max-width: 768px) {
    .about-container {
        padding: 20px 0;
    }

    .about-title,
    .about-description,
    .section-title,
    .features-list,
    .how-to-list,
    .contact-section {
        padding: 0 16px;
    }
}

.about-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.about-description {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 40px;
    text-align: center;
}

.section-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #007bff;
}

.features-list, .how-to-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 40px;
}

.feature-item, .how-to-item {
    margin-bottom: 15px;
}

.feature-title, .how-to-step {
    font-weight: bold;
}

.feature-description, .how-to-description {
    margin-left: 10px;
    font-size: 16px;
    color: #555;
}

.contact-section {
    text-align: center;
}

.contact-description {
    font-size: 18px;
    line-height: 1.6;
}

.contact-link {
    color: #007bff;
    text-decoration: none;
}

.contact-link:hover {
    text-decoration: underline;
}
