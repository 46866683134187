.video-container {
    position: relative;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    background: #242424;
}

.video-container::before {
    display: none;
}

.video-js {
    position: relative !important;
    width: 100% !important;
    background-color: #242424;
    aspect-ratio: 16/9;
}

/* Fix text track display positioning */
.video-js .vjs-text-track-display {
    position: absolute !important;
    pointer-events: none;
}

.video-js .vjs-tech {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Portrait video adjustments */
.video-js.portrait-video {
    aspect-ratio: 9/16;
}

/* Control positioning */
.video-js .vjs-control-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(36, 36, 36, 0.9);
    z-index: 2;
}

.video-js .vjs-big-play-button {
    background-color: rgba(0, 0, 0, 0.45);
    border-color: #fff;
    border-radius: 50%;
    height: 3em;
    width: 3em;
    line-height: 3em;
    margin-left: -1.5em;
    margin-top: -1.5em;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
}

.video-js:hover .vjs-big-play-button {
    background-color: rgba(0, 0, 0, 0.6);
}

.video-js .vjs-slider {
    background-color: rgba(255, 255, 255, 0.3);
}

.video-js .vjs-play-progress {
    background-color: #1976d2;
}

.video-js .vjs-volume-level {
    background-color: #1976d2;
}

.video-js .vjs-progress-holder {
    height: 0.5em;
}

.video-js .vjs-progress-control:hover .vjs-progress-holder {
    height: 0.7em;
}

.video-js .vjs-loading-spinner {
    border: 3px solid rgba(255, 255, 255, 0.7);
}

.video-js .vjs-loading-spinner:before,
.video-js .vjs-loading-spinner:after {
    border-top-color: #1976d2;
}

.video-js .vjs-error-display {
    display: none;
}

.video-js.vjs-error {
    background-color: #363636;
}

.vjs-no-js {
    color: #fff;
    background-color: #363636;
    padding: 20px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
} 