.site-footer {
    background-color: #f8f9fa;
    padding: 40px 20px 20px;
    width: 100%;
    margin-top: auto;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
}

.footer-section {
    flex: 1;
    min-width: 200px;
}

.footer-section h3 {
    color: #333;
    font-size: 1.2em;
    margin-bottom: 15px;
    font-weight: 600;
}

.footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #666;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 0.9em;
}

.footer-section ul li a:hover {
    color: #007bff;
}

.footer-bottom {
    text-align: center;
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.footer-bottom p {
    color: #666;
    font-size: 0.9em;
    margin: 0;
}

/* Tablet */
@media (max-width: 768px) {
    .footer-content {
        gap: 30px;
    }
    
    .footer-section {
        flex: 1 1 calc(50% - 15px);
        min-width: 150px;
    }
}

/* Mobile */
@media (max-width: 480px) {
    .site-footer {
        padding: 30px 15px 15px;
    }

    .footer-content {
        gap: 20px;
        justify-content: space-between;
    }

    .footer-section {
        flex: 0 0 calc(50% - 10px);
        min-width: unset;
    }

    .footer-section h3 {
        margin-bottom: 10px;
        font-size: 1.1em;
    }

    .footer-section ul li {
        margin-bottom: 8px;
        font-size: 0.9em;
    }
} 