.event-length-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.event-length-alert-box {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.event-length-alert-box p {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.event-length-alert-box button {
    padding: 10px 20px;
    font-size: 1.2em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.event-length-alert-box button:hover {
    opacity: 0.8;
}
