/* src/components/InviteButton.css */
.invite-button {
    background-color: #2A2A2A;
    color: #E0E0E0;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease;
}

.invite-button:hover {
    background-color: #363636;
}

.invite-button svg {
    width: 14px;
    height: 14px;
}

.invite-button.icon-only {
    padding: 8px;
    border-radius: 4px;
    background: none;
}

.invite-button.icon-only:hover {
    color: #1DB954;
    background: rgba(29, 185, 84, 0.1);
}

.invite-button.icon-only svg {
    width: 16px;
    height: 16px;
}
