.user-list-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #242424;
    border-radius: 0;
    padding: 0;
    width: 400px;
    height: min(600px, 90vh);
    outline: none;
    color: #fff;
    display: flex;
    flex-direction: column;
}

.user-list-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #333;
    padding: 16px 24px;
}

.modal-header h2 {
    margin: 0;
    font-size: 16px;
}

.close-button {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    padding: 4px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-button:hover {
    color: #fff;
}

.user-list {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.user-list::-webkit-scrollbar {
    display: none;
}

.user-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 24px;
    border-bottom: 1px solid #333;
}

.user-list-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.user-avatar-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1px;
    width: 40px;
}

.user-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.display-name {
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 0 8px;
}

.username {
    font-size: 12px;
    color: #888;
    text-align: center;
}

.user-details {
    flex: 1;
}

.user-details h3 {
    margin: 0;
    font-size: 14px;
    color: #fff;
}

.user-subtitle {
    margin: 4px 0 0 0;
    font-size: 12px;
    color: #888;
}

.form-actions {
    display: none;
}

@media (max-width: 430px) {
    .user-list-modal {
        width: 100%;
        height: 100%;
        max-height: 100%;
        top: 0;
        left: 0;
        transform: none;
        position: fixed;
    }

    .user-list {
        max-height: calc(100vh - 57px);
    }
}

@media (max-width: 400px) {
    .user-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 300px) {
    .user-grid {
        grid-template-columns: repeat(3, 1fr);
    }
} 