.event-playlist-card {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    margin: 10px 0;
}

.event-playlist-card h3 {
    margin: 0 0 16px;
    color: #ffffff;
    font-size: 18px;
}

.spotify-connect-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #1DB954;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 500px;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.1em;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
}

.spotify-connect-button:hover {
    background-color: #1ed760;
    transform: scale(1.02);
}

.spotify-connect-button:active {
    background-color: #1aa34a;
    transform: scale(0.98);
}

.spotify-connect-button img {
    width: 24px;
    height: 24px;
}

.spotify-connect-button:disabled {
    background-color: #535353;
    cursor: not-allowed;
    opacity: 0.7;
}

.event-remove-button {
    margin-top: 15px;
    padding: 8px 16px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.event-remove-button:hover {
    background-color: #c0392b;
}

.event-remove-button:disabled {
    background-color: #535353;
    cursor: not-allowed;
    opacity: 0.7;
}

.playlist-error-container {
    background: #2c2c2c;
    padding: 20px;
    border-radius: 8px;
    margin: 15px 0;
    color: #ffffff;
}

.playlist-error-container p {
    margin-bottom: 15px;
    color: #ffffff;
}

.error-message {
    color: #e74c3c;
    margin-bottom: 10px;
    font-size: 14px;
}

/* Playlist selector styles */
.playlist-search {
    margin-bottom: 20px;
}

.playlist-search-input {
    width: 100%;
    padding: 12px;
    background-color: #2c2c2c;
    border: 2px solid #3c3c3c;
    border-radius: 25px;
    font-size: 16px;
    color: #ffffff;
    transition: border-color 0.3s ease;
}

.playlist-search-input:focus {
    outline: none;
    border-color: #1DB954;
}

.playlists-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.playlist-card {
    background: #2c2c2c;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease;
}

.playlist-card:hover {
    transform: translateY(-5px);
}

.playlist-card.clickable {
    cursor: pointer;
}

.playlist-card.clickable:hover {
    background: #3c3c3c;
}

.playlist-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.playlist-card h5 {
    margin: 10px;
    font-size: 16px;
    color: #ffffff;
}

.playlist-card p {
    margin: 0 10px 10px;
    color: #999999;
    font-size: 14px;
}

.collaborative-badge {
    background-color: #1DB954;
    color: white;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 12px;
    margin: 0 10px 10px;
    display: inline-block;
}

@media (max-width: 768px) {
    .playlists-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .playlist-card img {
        height: 150px;
    }

    .playlist-search-input {
        font-size: 14px;
        padding: 10px;
    }
} 