/* Container and Layout */
.event-body-content {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    background-color: #f0f0f0;
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.event-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 960px;
    padding: 0 10px;
}

/* Header Card */
.event-header-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 20px;

}

.event-header-left {
    flex: 1;
}

.event-header-right {
    display: flex;
    flex-direction: column; /* Stack the heading on top of the icons */
    align-items: flex-start; /* Align items to the start (left) */
    gap: 5px; /* Space between the heading and the icons */
    padding-right: 15px;
}

.event-header-right h2 {
    margin-bottom: 8px; /* Space between the heading and the icons */
}

.event-share-icons {
    display: flex;
    gap: 10px; /* Space between the icons */
}

.event-header-right button {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
}

/* Event Details and Actions */
.event-details-actions-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: 100%;
    text-align: left;
    position: relative;
}

.event-details {
    flex: 1;
    margin-bottom: 0;
}

.event-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto; /* Align to the right side */
    align-items: flex-end; /* Ensure buttons are aligned to the right */
    padding-right: 15px;
    margin-top: 20px; /* Add some space between the list and the buttons */
}

.event-button,
.invite-button,
.event-delete-button,
.event-save-button,
.event-remove-button {
    background-color: #007bff !important;
    color: white;
    border: none;
    padding: 10px 20px !important;
    border-radius: 5px !important;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    width: 175px;
}

.event-button:hover,
.event-delete-button:hover,
.event-save-button:hover,
.event-remove-button:hover {
    opacity: 0.8;
}

.event-attendees-card {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 20px;
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
}

.event-attendees-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
}

.event-attendee-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
    text-align: center;
    max-width: 65px;
    font-size: small;
}

.event-attendee-link {
    text-decoration: none;
    color: inherit;
}

.event-attendee-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.event-attendee-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

/* Playlist Card */
.event-playlist-card {
    background: #242424;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 10px;
    text-align: left;
}

.event-playlist-card h3 {
    margin-top: 0;
    margin-bottom: 15px;
}

.event-playlist-card iframe {
    width: 100%;
    border: none;
    border-radius: 8px;
    margin: 0;
}

/* Comments Section */
.comments-section {
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-top: 20px;
    text-align: left;
    position: relative;
}

.comments-section ul {
    list-style-type: none;
    padding-left: 3px;
    margin-top: 0;
}

.comments-section li {
    margin-bottom: 20px;
    text-align: left;
}

.comments-section textarea {
    width: 93%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-left: 20px;
}

.comments-section button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 20px;
}

.comments-section button:hover {
    opacity: 0.8;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .event-header-card,
    .event-details-actions-card,
    .event-attendees-card,
    .event-playlist-card,
    .comments-section {
        padding: 10px;
    }

    .event-attendees-list {
        flex-direction: column;
        align-items: flex-start;
    }

    .reply-link,
    .delete-link {
        margin-bottom: 5px;
    }

    .comments-section textarea {
        width: 95%;
        margin-left: 0;
        margin-right: 10px;
    }

    .comments-section ul {
        margin-top: 0;
    }

    .comment {
        padding: 8px;
    }

    .comment-header {
        align-items: center;
    }

    .left-side,
    .right-side {
        width: 100%;
        min-width: unset;
    }
}
