:root {
    --background-paper: #ffffff;
    --text-primary: #333333;
    --text-secondary: #666666;
    --primary-main: #2196f3;
    --primary-dark: #1976d2;
    --primary-light: rgba(33, 150, 243, 0.1);
    --error-main: #f44336;
    --error-dark: #d32f2f;
    --border-color: #e0e0e0;
    --shadow-small: 0 1px 3px rgba(0, 0, 0, 0.1);
    --shadow-medium: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Container and List */
.rainbow-comments-container {
    padding: 24px;
    background-color: var(--background-paper);
    border-radius: 12px;
    margin-top: 24px;
    box-shadow: var(--shadow-medium);
}

.rainbow-comments-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.rainbow-comments-container li {
    list-style-type: none;
}

/* Override Material-UI alignments */
.MuiCardHeader-content {
    text-align: left !important;
}

.MuiCardHeader-title {
    text-align: left !important;
}

.MuiCardHeader-subheader {
    text-align: left !important;
}

.MuiTypography-root {
    text-align: left !important;
}

.MuiCardContent-root {
    text-align: left !important;
}

/* Comment Header Styles */
.comment-header {
    padding: 16px !important;
    border-bottom: 1px solid var(--border-color);
}

.comment-profile-pic {
    width: 36px !important;
    height: 36px !important;
    border-radius: 50%;
    margin-right: 12px !important;
}

.comment-content {
    padding: 16px !important;
    position: relative;
}

.comment-content p {
    margin: 0 0 12px 0 !important;
    color: var(--text-primary);
    font-size: 14px;
    line-height: 1.6;
}

/* Comment actions */
.comment-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    padding: 0 16px 16px !important;
}

/* Reply section */
.replies-container {
    padding: 0 16px 16px 48px;
}

/* Remove the bullet points by removing the ::before pseudo-element */
.replies-container::before {
    display: none;
}

/* Individual reply */
.reply {
    background-color: var(--background-paper);
    border-radius: 8px;
    margin-bottom: 12px;
    border: 1px solid var(--border-color);
}

.reply-header {
    padding: 12px 16px !important;
}

.reply .comment-profile-pic {
    width: 32px !important;
    height: 32px !important;
}

.reply-content {
    padding: 0 16px 12px !important;
}

.reply-actions {
    padding: 0 16px 12px !important;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

/* Action buttons */
.reply-link, .comment-delete-link, .reply-delete-button {
    font-size: 12px !important;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    cursor: pointer;
}

.reply-link {
    color: var(--primary-main);
}

.comment-delete-link, .reply-delete-button {
    color: var(--error-main);
}

.reply-link:hover {
    color: var(--primary-dark);
}

.comment-delete-link:hover, .reply-delete-button:hover {
    color: var(--error-dark);
}

/* Username and timestamp */
.comment-username {
    font-weight: 600 !important;
    color: var(--text-primary) !important;
    font-size: 14px !important;
    display: block !important;
}

.comment-timestamp, .reply-date {
    color: var(--text-secondary) !important;
    font-size: 11px !important;
    display: block !important;
    margin-top: 2px !important;
}

/* Reply form */
.reply-form {
    margin: 8px 0;
    padding: 12px;
    background-color: var(--background-paper);
    border-radius: 8px;
    border: 1px solid var(--border-color);
}

.reply-input {
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    font-size: 14px;
    resize: vertical;
    min-height: 60px;
    margin-bottom: 8px;
    transition: all 0.2s ease;
}

.reply-input:focus {
    outline: none;
    border-color: var(--primary-main);
    box-shadow: 0 0 0 2px var(--primary-light);
}

/* Form Styles */
.galaxy-form textarea,
.galaxy-form input,
.enchanted-textarea,
.stellar-input,
.radiant-input,
.nebula-textarea {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    margin-bottom: 12px;
    background-color: var(--background-paper);
    color: var(--text-primary);
    font-size: 14px;
    transition: all 0.2s ease;
}

.galaxy-form textarea:focus,
.galaxy-form input:focus {
    outline: none;
    border-color: var(--primary-main);
    box-shadow: 0 0 0 2px var(--primary-light);
}

.galaxy-form .cosmic-button {
    padding: 8px 16px;
    border: none;
    border-radius: 6px;
    background-color: var(--primary-main);
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.galaxy-form .cosmic-button:hover {
    background-color: var(--primary-dark);
}

/* Like Button Styles */
.like-container {
    display: flex;
    align-items: center;
    gap: 4px;
}

.like-container .MuiIconButton-root {
    padding: 6px !important;
}

.like-container .MuiSvgIcon-root {
    font-size: 18px !important;
}

.like-container .MuiTypography-caption {
    font-size: 12px !important;
    color: var(--text-secondary) !important;
}

/* Ensure proper spacing between content sections */
.comment-header + .comment-content {
    padding-top: 0 !important;
}

/* Add subtle divider between content and actions */
.comment-content {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 8px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .rainbow-comments-container {
        padding: 16px;
    }

    .replies-container {
        margin-left: 16px;
    }
}

@media (max-width: 480px) {
    .comment-header {
        padding: 8px 12px !important;
    }

    .comment-content {
        padding: 0 12px 12px !important;
    }

    .comment-actions {
        padding: 8px 12px;
    }
}