html, body, #root {
    height: 100%;
    margin: 0;
}

html, body {
    overflow-x: hidden;
}

.container {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: Arial, sans-serif;
    overflow-x: hidden; /* Prevents scrolling issues */
}

.controls {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px 10px;
    background-color: #f8f9fa;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.controlsGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.inputGroup {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-left: 5px;
    padding-right: 2px;
}

.input {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 100%;
}

.button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
}

.placesList {
    width: 95%;
    max-width: 220px;
    background-color: #f8f9fa;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.placesList h3 {
    margin: 5px;
    padding: 2px !important;
}

.placesList ul {
    padding-bottom: 60px;
    margin-top: 0px;
    margin-bottom: 130px;
}

.sidebar h3 {
    text-align: center; /* Center align the h3 */
    padding: 10px;
}

.sidebar ul {
    padding: 5px; /* Reduced padding for the ul */
    list-style: none; /* Remove default list styling */
}

.placeItem {
    padding: 7px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

/* Explicitly remove any list marker styles, even if not using list elements */
.placeItem::marker {
    content: '';
}

.sidebar {
    position: relative;
    width: 220px; /* Fixed width */
    padding-right: 5px; /* Add padding to the right */
    background-color: #f8f9fa;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: transform 0.3s ease;
    height: 100vh; /* Ensure the sidebar takes full height */
    overflow-y: auto; /* Enable vertical scrolling */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
}

.sidebar.hidden {
    transform: translateX(-300px); /* Move sidebar out of view */
}

.sidebar.open {
    transform: translateX(0); /* Move sidebar into view */
}

.gm-style-iw .gm-style-iw-c {
    padding-inline-end: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
    max-width: 648px;
    max-height: 1159px;
    min-width: 0px;
}

.gm-ui-hover-effect {
    height: 15px !important;
}

.gm-style-iw-ch {
    padding: 0px;
}

.gm-style-iw-d p {
    margin: 3px;
}

.gm-style-iw-d button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-top: 5px;
}

.gm-style-iw-d h2 {
    margin-top: 0px;
    margin-bottom: 5px;
}

.gm-style-iw-d {
    background: none;
    display: block;
    border: 0px;
    margin: 0px;
    padding: 0px;
    text-transform: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    user-select: none;
}

@media (max-width: 768px) {
    .controls {
        padding: 0 5px 5px;
    }

    .controlsGroup {
        gap: 5px;
    }

    .inputGroup {
        gap: 5px;
        padding-left: 2px;
        padding-right: 1px;
    }

    .input {
        padding: 6px;
        font-size: 14px;
    }

    .button {
        padding: 6px 12px;
        font-size: 14px;
    }

    .placesList {
        max-width: 100%;
    }

    .placeItem {
        padding: 5px;
    }

    .sidebar {
        padding-top: 50px;
    }
}

.open-mat-status {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.open-mat-status .indicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 10px;
}

.indicator.open {
    background-color: green;
}

.indicator.closed {
    background-color: red;
}

.close-button {
    background: none;
    border: none;
    font-size: 36px; /* Increase the size of the button */
    cursor: pointer;
    right: 10px; /* Move it to the right */
    top: 10px; /* Keep it at the top */
    display: flex;
    justify-content: end;
    margin-left: auto;
    margin-right: 0;
}

#install-button {
    position: fixed;
    bottom: 20px;
    left: 20px; /* Move to the left side */
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    z-index: 1003;
}

#install-button:hover {
    background-color: #0056b3;
}

.ios-install-instructions {
    position: fixed;
    bottom: 100px;
    right: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1000;
    max-width: 300px;
}

.ios-install-instructions p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

.ios-install-instructions img {
    width: 24px;
    height: 24px;
    margin-left: 2px;
}

.ios-install-instructions button {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.ios-install-instructions button:hover {
    background-color: #0056b3;
}

/* Responsive styles */
@media (max-width: 3450px) {
    .container {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .sidebar {
        position: absolute;
        left: -100%;
        z-index: 999;
    }

    .use-location-button {
        margin-left: 20px;
    }

    .sidebar.open {
        left: 0;
        z-index: 999;
        padding-top: 75px;
        height: auto;
    }

    .map-container {
        width: 100%;
        height: 100%;
        padding-top: 35px;
    }

    .google-map {
        width: 100%;
        height: 100%;
    }

    .placesList h3 {
        margin-right: 20px;
    }
}
@media (max-width: 768px) {
    input[type="text"],
    textarea {
        font-size: 16px;
        -webkit-text-size-adjust: 100%; /* Prevents zooming */
    }
}

.app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.main-content {
    flex: 1;
    width: 100%;
}