.events-modal {
    position: absolute;
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    outline: none;
}

.events-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.events-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    margin: 16px 0;
}

.event-item {
    text-decoration: none;
    color: inherit;
    background: #f8f9fa;
    padding: 12px;
    border-radius: 8px;
    transition: background-color 0.2s;
}

.event-item:hover {
    background-color: #e9ecef;
}

.event-details {
    text-align: center;
}

.event-details h3 {
    margin: 0;
    font-size: 14px;
    color: #333;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 1.2;
}

.event-details p {
    margin: 4px 0;
    font-size: 12px;
    color: #666;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 1.2;
}

.form-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.form-actions button {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.2s;
}

.form-actions button:hover {
    background-color: #0056b3;
}

h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
}

@media (max-width: 400px) {
    .events-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 300px) {
    .events-grid {
        grid-template-columns: 1fr;
    }
} 