.admin-dashboard {
    margin-top: 65px;
}

.admin-dashboard-container {
    padding: 20px;
    background-color: #f8f9fa;
    min-height: 100vh; /* Ensure the dashboard takes up the full height of the viewport */
}

.admin-dashboard-header, .dashboard-stats {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.admin-dashboard-header {
    border-bottom: 2px solid #dee2e6;
    padding-bottom: 10px;
}

.admin-dashboard-title {
    font-size: 24px;
    font-weight: bold;
    color: #343a40;
}

.admin-dashboard-actions, .dashboard-section {
    margin-bottom: 20px;
}

.admin-dashboard-action-button, .action-button, .settings-button, .resolve-button, .claim-item button {
    padding: 10px 15px;
    margin: 5px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.admin-dashboard-action-button:hover, .action-button:hover, .settings-button:hover, .resolve-button:hover {
    background-color: #0056b3;
}

.admin-dashboard-content, .dashboard-charts {
    margin-top: 20px;
}

.admin-dashboard-table, .user-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.admin-dashboard-table th, .admin-dashboard-table td, .user-table th, .user-table td {
    border: 1px solid #dee2e6;
    padding: 10px;
    text-align: left;
}

.admin-dashboard-table th, .user-table th {
    background-color: #e9ecef;
    color: #495057;
}

.admin-dashboard-table td, .user-table td {
    background-color: #ffffff;
    color: #495057;
}

.admin-dashboard-table tr:nth-child(even), .user-table tr:nth-child(even) {
    background-color: #f1f3f5;
}

.stats-card, .user-table, .reported-content, .settings, .dashboard-section {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.dashboard-section {
    background-color: #fff5e6;
}

.stats-card, .stats-card:last-child {
    text-align: center;
    width: 30%;
}

.dashboard-section h2, .admin-dashboard-header h2 {
    margin-bottom: 10px;
}

.claims-list, .reported-content ul {
    list-style-type: none;
    padding: 0;
}

.claim-item, .reported-content li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.claim-item:last-child, .reported-content li:last-child {
    border-bottom: none;
}

.claim-item button {
    background-color: #4CAF50;
}

.claim-item button:hover {
    background-color: #45a049;
}

.status-pending {
    background-color: #ffc107;
    color: #856404;
}

.status-approved {
    background-color: #28a745;
    color: white;
}

.status-denied {
    background-color: #dc3545;
    color: white;
}

.recharts-legend-item {
    cursor: pointer;
    transition: color 0.3s ease;
}

.recharts-legend-item:hover {
    color: #555555;
}

.recharts-legend-item .recharts-legend-icon {
    transition: fill 0.3s ease;
}

.recharts-legend-item .recharts-legend-icon.inactive {
    fill: #aaa !important; /* Gray out inactive icons */
}
.custom-legend {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 20px;
}

.custom-legend li {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease;
}

.custom-legend li:hover {
    color: #333; /* Darken the color on hover */
}

.custom-legend .legend-icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
}

.custom-legend .legend-icon.inactive {
    background-color: #aaa;
}
.dashboard-section {
    margin-top: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-section h2 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
}

.claims-list,
.calendar-requests-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.claim-item,
.calendar-request-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s;
}

.claim-item:hover,
.calendar-request-item:hover {
    background-color: #f1f1f1;
}

.claim-item p,
.calendar-request-item p {
    margin: 0;
    font-size: 1em;
    color: #555;
}

.claim-item button,
.calendar-request-item button {
    padding: 5px 10px;
    font-size: 0.9em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.claim-item button:hover,
.calendar-request-item button:hover {
    opacity: 0.8;
}

.claim-item button:first-of-type,
.calendar-request-item button:first-of-type {
    background-color: #28a745;
    color: white;
    margin-right: 10px;
}

.claim-item button:last-of-type,
.calendar-request-item button:last-of-type {
    background-color: #dc3545;
    color: white;
}
.calendar-requests-list .calendar-request-item button {
    margin: 5px;
}

.calendar-requests-list .calendar-request-item button:nth-child(2) {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}
