.recurrence-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.recurrence-modal-content {
    background: #242424;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    z-index: 1001;
}

.recurrence-modal-content h3 {
    margin-top: 0;
    font-size: 24px;
    color: #ffffff;
}

.recurrence-options {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.recurrence-options label {
    color: #ffffff;
    cursor: pointer;
}

.recurrence-options input[type="radio"] {
    margin-right: 5px;
}

.recurrence-occurrences {
    margin: 15px 0;
    color: #ffffff;
}

.recurrence-occurrences label {
    color: #ffffff;
    display: block;
    margin-bottom: 8px;
}

.recurrence-occurrences input[type="number"] {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #333333;
    background-color: #1a1a1a;
    color: #ffffff;
}

.recurrence-modal-actions {
    display: flex;
    justify-content: flex-end;
}

.recurrence-modal-actions button {
    padding: 10px 20px;
    margin-left: 10px;
    background-color: #3b82f6;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.recurrence-modal-actions button:hover {
    opacity: 0.8;
}

.recurrence-modal-actions button:last-child {
    background-color: #1a1a1a;
}

.recurrence-modal-actions button:last-child:hover {
    background-color: #2a2a2a;
}
