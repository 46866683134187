.notifications-container {
    max-width: 100%;
    margin: 0 auto;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 16px;
}

.notifications-header h2 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.notifications-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.notification-item {
    padding: 16px;
    background-color: var(--card-background-color);
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.notification-item:hover {
    background-color: var(--card-hover-background-color);
}

.loading, .no-more, .no-activities {
    text-align: center;
    padding: 20px;
    color: var(--text-secondary-color);
}

/* Mobile styles */
@media (max-width: 768px) {
    .notifications-container {
        border-radius: 0;
        box-shadow: none;
    }

    .notifications-header {
        padding: 0 8px;
    }

    .notification-item {
        padding: 12px;
    }
}
