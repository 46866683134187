/* ContactForm.css */

.contact-form-card {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    padding-top: 56px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.contact-form-card h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.contact-form label {
    font-size: 16px;
    color: #555;
    margin-bottom: 8px;
    text-align: left;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.contact-form input[type="text"]:focus,
.contact-form input[type="email"]:focus,
.contact-form textarea:focus {
    border-color: #007bff;
    outline: none;
}

.contact-form button[type="submit"] {
    padding: 12px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.contact-form button[type="submit"]:hover {
    background-color: #0056b3;
}

.success-message {
    text-align: center;
    font-size: 18px;
    color: #28a745;
    margin-top: 20px;
}

.success-message h2 {
    font-size: 24px;
    color: #28a745;
}

.success-message p {
    font-size: 16px;
    color: #333;
}

/* Responsive design */
@media (max-width: 768px) {
    .contact-form-card {
        padding: 20px;
        padding-top: 56px;
    }

    .contact-form button[type="submit"] {
        padding: 10px 15px;
        font-size: 14px;
    }

    .contact-form input[type="text"],
    .contact-form input[type="email"],
    .contact-form textarea {
        padding: 10px;
        font-size: 14px;
    }

    .contact-form label {
        font-size: 14px;
    }

    .success-message h2 {
        font-size: 20px;
    }

    .success-message p {
        font-size: 14px;
    }
}
