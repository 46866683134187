.manage-roles {
    padding: 20px;
    margin-top: 40px;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.manage-roles h1 {
    font-size: 24px;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 20px;
    border-bottom: 2px solid #dee2e6;
    padding-bottom: 10px;
}

.roles-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.roles-table th,
.roles-table td {
    border: 1px solid #dee2e6;
    padding: 12px 15px;
    text-align: left;
}

.roles-table th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    text-align: center;
}

.roles-table td {
    color: #495057;
    text-align: center;
}

.roles-table tr:nth-child(even) {
    background-color: #f1f3f5;
}

.roles-table input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.roles-table td:first-child {
    text-align: left;
    padding-left: 20px;
}

.roles-table td:last-child {
    padding-right: 20px;
}

.save-button {
    padding: 12px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-button:hover {
    background-color: #218838;
}

.save-button:disabled {
    cursor: not-allowed;
}
