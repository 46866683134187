.floating-logo {
    position: fixed;
    bottom: 80px; /* Increased to account for bottom bar */
    right: 20px;
    width: 80px;
    height: auto;
    z-index: 1000;
    opacity: 0.8;
    transition: all 0.3s ease;
    filter: drop-shadow(0 0 13px rgba(255, 255, 255, 0.45));
}

.floating-logo:hover {
    opacity: 1;
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.75));
}

@media (min-width: 769px) {
    .floating-logo {
        bottom: 20px; /* Reset to original position on desktop */
    }
}
