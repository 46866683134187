.edit-profile-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 600px;
    max-height: 85vh;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    outline: none;
    margin: 5px auto;
    overflow-y: auto;
}

@media (max-width: 768px) {
    .edit-profile-modal {
        width: 95%;
    }

    .modal-content {
        padding: 24px;
    }
}

.modal-content {
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.modal-content::-webkit-scrollbar {
    width: 8px;
}

.modal-content::-webkit-scrollbar-track {
    background: #f8f9fa;
    border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 4px;
    border: 2px solid #f8f9fa;
}

.edit-profile-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 5px;
}

.form-group label {
    font-size: 13px;
    font-weight: 600;
    color: #333;
    min-width: 70px;
}

.form-group input,
.form-group select,
.form-group textarea {
    flex: 1;
    padding: 6px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 13px;
    line-height: 1.4;
    color: #000;
    background-color: #fff;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

.hometown-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 5px;
    width: 100%;
}

.hometown-group label {
    font-size: 13px;
    font-weight: 600;
    color: #333;
    min-width: 70px;
    flex-shrink: 0;
}

.hometown-inputs {
    display: flex;
    gap: 5px;
    flex: 1;
    max-width: calc(100% - 78px); /* 70px label + 8px gap */
}

.hometown-inputs select,
.hometown-inputs input {
    flex: 1;
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 8px;
    font-size: 13px;
    min-width: 0; /* Allows flex items to shrink below content size */
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.form-group select {
    padding-right: 28px;
    background-position: right 8px center;
    background-color: #fff;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24'%3E%3Cpath fill='%23333' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
}

.calendar-request-group {
    margin-bottom: 15px;
}

.calendar-request-group label {
    display: flex;
    align-items: center;
    font-size: 0.85rem;
    font-weight: 600;
    color: #333;
    justify-content: flex-start;
}

.calendar-request-group label span {
    margin-right: 10px; /* Adjust the spacing between the text and the checkbox */
}

.calendar-request-group input[type="checkbox"] {
    order: 1; /* Ensure the checkbox is placed after the text */
}

.save-button,
.cancel-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.8rem;
    height: 32px;
    min-width: 80px;
    box-sizing: border-box;
    font-family: inherit;
    margin: 0;
}

.save-button {
    background-color: #007bff;
    color: white;
}

.save-button:hover:not(.disabled) {
    background-color: #0056b3;
}

.save-button.disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
    pointer-events: none;
}

.cancel-button {
    background-color: #f8f9fa;
    color: #6c757d;
    border: 1px solid #dee2e6;
}

.cancel-button:hover {
    background-color: #e9ecef;
}

.creator-settings-section {
    border-top: 1px solid #e0e0e0;
    margin-top: 10px;
    padding-top: 10px;
    text-align: left;
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    padding: 8px;
    margin: 8px 0;
}

.creator-settings-section h3 {
    color: #333;
    font-size: 0.95rem;
    margin-bottom: 8px;
    text-align: left;
}

.creator-settings-section .form-group {
    margin-bottom: 15px;
}

.creator-settings-section label {
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 600;
    font-size: 0.85rem;
    color: #333;
}

.creator-settings-section input[type="checkbox"] {
    width: auto;
    margin-right: 8px;
}

.creator-settings-section input[type="number"] {
    width: 120px;
    padding: 4px 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.85rem;
}

.creator-settings-section textarea {
    width: 100%;
    min-height: 80px;
    padding: 4px 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    font-size: 0.85rem;
}

@media (max-width: 768px) {
    .creator-settings-section {
        margin-top: 15px;
        padding-top: 15px;
    }

    .creator-settings-section input[type="number"] {
        width: 100%;
    }
}

.creator-mode-toggle {
    margin: 20px 0;
    padding: 15px;
    background: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e9ecef;
}

.creator-mode-toggle label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 0.85rem;
    color: #333;
    margin-bottom: 6px;
}

.creator-mode-toggle input[type="checkbox"] {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.creator-mode-description {
    color: #6c757d;
    font-size: 0.8rem;
    margin: 0;
    line-height: 1.3;
}

.settings-toggles-container {
    background: #f8f9fa;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    padding: 5px;
    margin: 5px 0;
}

.settings-toggle-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.toggle-header {
    margin-bottom: 4px;
    text-align: left;
}

.toggle-header h3 {
    font-size: 0.8rem;
    margin: 0 0 2px 0;
    color: #333;
    font-weight: 600;
}

.toggle-header p {
    font-size: 0.75rem;
    color: #6c757d;
    margin: 0;
}

.toggle-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;
}

.toggle-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.toggle-label {
    font-weight: 600;
    font-size: 0.9rem;
    color: #333;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #007bff;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.toggle-description {
    font-size: 0.85rem;
    color: #666;
    margin: 0;
    padding: 0;
    line-height: 1.4;
}

body > div:nth-child(6) > div > div > div > div.settings-toggles-container > div > div:nth-child(3) > div > p:nth-child(1) {
    text-align: left;
}

.toggle-description ul {
    margin: 4px 0;
    padding-left: 12px;
    text-align: left;
}

.toggle-description li {
    margin: 1px 0;
    text-align: left;
}

.toggle-description .note {
    margin-top: 10px;
    font-style: italic;
    text-align: left;
}

@media (max-width: 768px) {
    .settings-toggles-container {
        padding: 15px;
        margin: 15px 0;
    }

    .form-actions {
        flex-direction: row;
        gap: 8px;
    }

    .save-button,
    .cancel-button {
        width: auto;
        min-width: 80px;
    }
}

.modal-content h2 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin: 0 0 5px 0;
}

.username-group {
    margin-bottom: 5px;
}

.username-input-container {
    position: relative;
}

.username-input-container input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #fff;
    color: #000;
}

.username-input-container input.error {
    border-color: #dc3545;
}

.username-input-container input:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
}

.checking-username {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: #666;
}

.error-message {
    font-size: 11px;
    margin-top: 4px;
}

.username-help {
    font-size: 11px;
    color: #666;
    margin-top: 4px;
    line-height: 1.3;
}
