/* src/components/Login.css */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    background-color: #f0f0f0;
    height: 100vh;
    justify-content: center;
}

.login-logo {
    width: 200px; /* Adjust the size as necessary */
}

/* src/components/Login.css */

.login-button {
    margin: 10px 0;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #4285F4;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.login-button.facebook-button {
    background-color: #3b5998; /* Facebook blue */
}

.login-button:hover {
    opacity: 0.8;
}


/*.floating-logo {*/
/*    display: none;*/
/*}*/